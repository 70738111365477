<template>
  <div
    class="csn-side-bar csn-search"
    :class="{ 'csn-search-displayed': SHOWS_SEARCH_MENU }"
  >
    <section class="csn-search-section-1">
      <div class="csn-search-header">
        <span class="csn-search-title">{{ t('search') }}</span>
        <div class="csn-search-close-button" @click="handleClose">
          <CloseIcon />
        </div>
      </div>
      <div class="csn-search-input-wrapper">
        <TextField
          v-model="search"
          containerClass="csn-search-input"
          @enter="handleSearch"
        />
        <div class="csn-search-glass-icon-wrapper" @click="handleSearch">
          <span
            class="casino-icon casino-icon-search csn-search-glass-icon"
          ></span>
        </div>
      </div>
    </section>
    <section class="csn-search-section-2">
      <span class="csn-search-filter-header">
        {{ t('search_input_options') }}
      </span>

      <div v-for="(filterType, key) in filter" :key="key">
        <div class="csn-search-fitler" @click="selectFilter(filterType)">
          <div class="csn-search-fitler-icon-container">
            <component :is="filterIconComponent[filterType]"></component>
          </div>
          <span class="csn-search-fitler-name">
            {{ getFilterName(filterType) }}
          </span>
          <div
            class="csn-search-fitler-arrow-container"
            :class="{
              'csn-search-fitler-arrow-container-selected':
                openedFilter === filterType,
            }"
          >
            <SlideArrow />
          </div>
        </div>
        <div
          class="csn-search-fitler-list"
          :style="
            getListStyle({
              filter: filterType,
              list: sortedFilterList[filterType],
            })
          "
        >
          <span
            v-for="(option, index) in sortedFilterList[filterType]"
            :key="index"
            class="csn-search-fitler-list-item"
            :class="{
              'csn-search-fitler-list-item-selected':
                selectedOptions[filterType] === option.id,
            }"
            @click="selectFilterOption({ filterType, option: option.id })"
          >
            {{ getFilterOptionName(option.name) }}
          </span>
        </div>
      </div>

      <div class="csn-search-reset-btn-container">
        <button
          class="btn casino-btn csn-search-reset-btn casino-btn-default"
          @click="resetFilters"
        >
          <span>{{ t('reset_filter') }}</span>
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { MainApi } from '@/api'
import {
  SEARCH,
  Module,
  SHOWS_SEARCH_MENU,
  TOGGLE_SEARCH_MENU,
  EMPTY_STRING,
  Digit,
  Filter,
  FilterByFilter,
  OrderByFilter,
  RouteName,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  OrderByFilterId,
  FilterByFilderId,
  SEARCH_QUERY,
  CURRENT_LANGUAGE_TRANSLATION,
  TRANSLATION,
} from '@/constants'
import {
  indexBy,
  stringComparator,
  equals,
  pipe,
  map,
  sort,
  navigateTo,
  dest,
} from '@/helpers'

const ID = 'id'
const NAME = 'name'
const HOT_FLAG = 'hotFlag'
const NEW_FLAG = 'newFlag'

const HEIGHT_ = 'height: '
const PX = 'px'

const initialFilterOptions = {
  [Filter.ALL_CATEGORIES]: Filter.ALL_CATEGORIES,
  [Filter.ALL_PROVIDERS]: Filter.ALL_PROVIDERS,
  [Filter.FILTER_BY]: Filter.FILTER_BY,
  [Filter.ORDER_BY]: Filter.ORDER_BY,
}

export default {
  name: SEARCH,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    TextField: () => import('@/components/TextField'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  data: () => ({
    search: EMPTY_STRING,
    openedFilter: EMPTY_STRING,
    selectedOptions: { ...initialFilterOptions },
    categoryFilter: EMPTY_ARRAY,
    providerFilter: EMPTY_ARRAY,
    filterByFilter: EMPTY_ARRAY,
    orderByFilter: EMPTY_ARRAY,
    params: EMPTY_OBJECT,
  }),
  computed: {
    initialOptions: () => Object.freeze(initialFilterOptions),
    ...mapState(Module.MAIN, [SHOWS_SEARCH_MENU]),
    ...mapState(Module.LANGUAGE, [TRANSLATION]),
    ...mapGetters({ searchQuery: dest([Module.LOCATION, SEARCH_QUERY]) }),
    ...mapGetters({
      currentLangTranslation: dest([
        Module.LANGUAGE,
        CURRENT_LANGUAGE_TRANSLATION,
      ]),
    }),
    t() {
      return this.$createComponentTranslator(SEARCH)
    },
    filter: () => Filter,
    sortedFilterList() {
      return {
        [Filter.ALL_CATEGORIES]: this.categoryFilter,
        [Filter.ALL_PROVIDERS]: this.providerFilter,
        [Filter.FILTER_BY]: this.filterByFilter,
        [Filter.ORDER_BY]: this.orderByFilter,
      }
    },
    filterDictionary() {
      return {
        [Filter.ALL_CATEGORIES]: pipe(
          map((item) => ({
            ...item,
            ...(isFinite(item.id) ? { hasNoTrans: true } : EMPTY_OBJECT),
          })),
          indexBy(ID),
        )(this.categoryFilter),
        [Filter.ALL_PROVIDERS]: pipe(
          map((item) => ({
            ...item,
            ...(isFinite(item.id) ? { hasNoTrans: true } : EMPTY_OBJECT),
          })),
          indexBy(ID),
        )(this.providerFilter),
        [Filter.FILTER_BY]: indexBy(ID)(this.filterByFilter),
        [Filter.ORDER_BY]: indexBy(ID)(this.orderByFilter),
      }
    },
    filterIconComponent() {
      return {
        [Filter.ALL_CATEGORIES]: () =>
          import('@/components/svg/AllCategoryFilterIcon'),
        [Filter.ALL_PROVIDERS]: () =>
          import('@/components/svg/AllProviderFilterIcon'),
        [Filter.FILTER_BY]: () => import('@/components/svg/FilterByFilterIcon'),
        [Filter.ORDER_BY]: () => import('@/components/svg/OrderByFilterIcon'),
      }
    },
  },
  watch: {
    params: {
      deep: true,
      handler(params) {
        if (this.$router.history.current?.query) {
          !equals(this.$router.history.current.query, this.params) &&
            navigateTo(
              { name: RouteName.SEARCH_RESULT, query: params },
              { isStrict: true },
            )
        }
      },
    },
    searchQuery: {
      immediate: true,
      handler(searchQuery) {
        this.search !== searchQuery && (this.search = searchQuery)
      },
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_SEARCH_MENU]),
    handleClose() {
      this.TOGGLE_SEARCH_MENU(false)
    },
    selectFilter(value) {
      this.openedFilter === value
        ? (this.openedFilter = EMPTY_STRING)
        : (this.openedFilter = value)
    },
    getListStyle({ filter, list }) {
      return this.openedFilter === filter
        ? `${HEIGHT_}${Object.values(list).length * Digit.FIFTY}${PX}`
        : `${HEIGHT_}${Digit.NOUGHT}`
    },
    selectFilterOption({ filterType, option }) {
      this.selectedOptions[filterType] = option
      this.handleSearch()
    },
    resetFilters() {
      this.selectedOptions = { ...initialFilterOptions }
      this.params = EMPTY_OBJECT
    },
    handleSearch() {
      const category = this.selectedOptions[Filter.ALL_CATEGORIES]
      const vendor = this.selectedOptions[Filter.ALL_PROVIDERS]
      const order = this.selectedOptions[Filter.ORDER_BY]

      const hasCategoryFilter =
        category !== this.initialOptions[Filter.ALL_CATEGORIES]
      const hasProviderFilter =
        vendor !== this.initialOptions[Filter.ALL_PROVIDERS]
      const hasOrderFilter = order !== this.initialOptions[Filter.ORDER_BY]
      const isNewFlag =
        this.selectedOptions[Filter.FILTER_BY] ===
        FilterByFilderId[FilterByFilter.NEW_GAMES]
      const isHotFlag =
        this.selectedOptions[Filter.FILTER_BY] ===
        FilterByFilderId[FilterByFilter.TOP_GAMES]

      this.params = {
        ...(this.search ? { search: this.search } : EMPTY_OBJECT),
        ...(hasCategoryFilter ? { category } : EMPTY_OBJECT),
        ...(hasProviderFilter ? { vendor } : EMPTY_OBJECT),
        ...(hasOrderFilter ? { order } : EMPTY_OBJECT),
        ...(isNewFlag ? { newFlag: Digit.ONE } : EMPTY_OBJECT),
        ...(isHotFlag ? { hotFlag: Digit.ONE } : EMPTY_OBJECT),
      }
    },
    getFilterName(filterType) {
      const option = this.selectedOptions[filterType]
      const filter = this.filterDictionary[filterType][option]
      const { name, hasNoTrans } = filter

      return hasNoTrans ? name : this.t(name)
    },
    getFilterOptionName(name) {
      const currentLanguageKey = this.currentLangTranslation?.[SEARCH]?.[name]
      const defaultLanguageKey = this.TRANSLATION['en']?.[SEARCH]?.[name]

      return currentLanguageKey || defaultLanguageKey ? this.t(name) : name
    },
  },
  async created() {
    const isSearchResultPage =
      this.$router.history.current?.name === RouteName.SEARCH_RESULT
    const params = this.$router.history.current.query || EMPTY_OBJECT

    const initialCategoryValue = {
      id: Filter.ALL_CATEGORIES,
      name: Filter.ALL_CATEGORIES,
    }
    const initialProviderValue = {
      id: Filter.ALL_PROVIDERS,
      name: Filter.ALL_PROVIDERS,
    }
    const initialFilterByValue = {
      id: Filter.FILTER_BY,
      name: Filter.FILTER_BY,
    }
    const filterByList = [
      {
        id: FilterByFilderId[FilterByFilter.NEW_GAMES],
        name: FilterByFilter.NEW_GAMES,
      },
      {
        id: FilterByFilderId[FilterByFilter.TOP_GAMES],
        name: FilterByFilter.TOP_GAMES,
      },
    ]
    const sortedFilterByList = filterByList.sort(stringComparator(NAME))

    this.categoryFilter = [initialCategoryValue]
    this.providerFilter = [initialProviderValue]
    this.filterByFilter = [initialFilterByValue, ...sortedFilterByList]
    this.orderByFilter = [
      {
        id: Filter.ORDER_BY,
        name: Filter.ORDER_BY,
      },
      {
        id: OrderByFilterId[OrderByFilter.A_Z],
        name: OrderByFilter.A_Z,
      },
      {
        id: OrderByFilterId[OrderByFilter.Z_A],
        name: OrderByFilter.Z_A,
      },
    ]

    try {
      const response = await MainApi.getSearchSidebar()

      if (response?.data) {
        const {
          categories = EMPTY_ARRAY,
          vendors = EMPTY_ARRAY,
        } = response.data

        const sortedCategoryList = pipe(
          map(({ id, ...rest }) => ({ ...rest, id: String(id) })),
          sort(stringComparator(NAME)),
        )(categories)

        const sortedProviderFilter = pipe(
          map(({ id, ...rest }) => ({ ...rest, id: String(id) })),
          sort(stringComparator(NAME)),
        )(vendors)

        this.categoryFilter = [...this.categoryFilter, ...sortedCategoryList]
        this.providerFilter = [...this.providerFilter, ...sortedProviderFilter]

        if (isSearchResultPage) {
          this.params = { ...params }
          this.params.search && (this.search = this.params.search)
          this.selectedOptions = {
            ...this.selectedOptions,
            ...(this.params.category
              ? { [Filter.ALL_CATEGORIES]: this.params.category }
              : EMPTY_OBJECT),
            ...(this.params.vendor
              ? { [Filter.ALL_PROVIDERS]: this.params.vendor }
              : EMPTY_OBJECT),
            ...(this.params.order
              ? { [Filter.ORDER_BY]: this.params.order }
              : EMPTY_OBJECT),
            ...(this.params.hotFlag
              ? { [Filter.FILTER_BY]: HOT_FLAG }
              : EMPTY_OBJECT),
            ...(this.params.newFlag
              ? { [Filter.FILTER_BY]: NEW_FLAG }
              : EMPTY_OBJECT),
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
}
</script>
